import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Types
import { ConversationsState } from './types/ConversationsState';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Utils
import initRequestData from 'redux/functions/initRequestData';
import conversationsService from './service';

const initialState: ConversationsState = {
  conversations: [],
  status: {
    fetch: 'idle',
  },
};

export const fetchConversations = createAsyncThunk(
  'conversations/fetchConversations',
  async (nameFilterValue: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.fetchConversations(
      jwtToken,
      URL_CONVERSATIONS,
      nameFilterValue
    );

    return response;
  }
);

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    resetConversations: (state) => {
      state.conversations = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.conversations = action.payload.items;
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        console.log('rejected fetchConversations: ', action.error);
      });
  },
});

export const { resetConversations } = conversationsSlice.actions;

export default conversationsSlice.reducer;
