// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Divider, Grid } from '@mui/material';
// Components
import ContactsItem from './ContactsItem/ContactsItem';
// Types
import { IBusiness, IContact } from '@trii/types/dist/Contacts';
import { IConversation } from '@trii/types/dist/Conversations';
import ConversationItem from './ConversationItem';

interface Props {
  contactList: IContact[] | IBusiness[] | IConversation[];
  title: string;
  type: 'contacts' | 'business' | 'conversations';
}

const ContactList = ({ contactList, title, type }: Props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Divider
        textAlign="left"
        sx={{
          fontWeight: '500',
          color: (theme) => theme.palette.text.primary,
          marginBottom: 3,
          marginTop: 2,
        }}
        
      >
        {t(title)}
      </Divider>
      <Grid container spacing={2}  > 
        {(type === 'contacts' || type === 'business') &&
          (contactList as IContact[] | IBusiness[]).map(
            (contact: IContact | IBusiness) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={contact.id} sx={{
                '&.MuiGrid-item': {

                  paddingTop: 0
                }
              }}>
                <ContactsItem contactData={contact} type={type} />
              </Grid>
            )
          )}
        {type === 'conversations' &&
          (contactList as IConversation[]).map(
            (contactConversation: IConversation) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={contactConversation.id} sx={{
                '&.MuiGrid-item': {

                  paddingTop: 0
                }
              }}>
                <ConversationItem
                  contactId={contactConversation.contactInfo.id}
                  id={contactConversation.id}
                  imageSrc={contactConversation.contactInfo.imageUrl}
                  name={contactConversation.contactInfo.name}
                />
              </Grid>
            )
          )}
      </Grid>
    </Box>
  );
};

export default ContactList;
