import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
// Types
import RootProps from './types/RootProps';

export default function Root(props: RootProps) {
  return (
    <Router>
      <Routes>
        <Route path="/*" Component={() => <App rootProps={props} />} />
      </Routes>
    </Router>
  );
}
