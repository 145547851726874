import { useContext, useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box } from '@mui/material';
// Components
import { ContactList, Tags } from './components';
// Context
import { HeaderContext } from 'components/Header/components/context/HeaderProvider';
import { useSelector } from 'react-redux';
import {
  getConversationsAmount,
  selectConversations,
} from 'redux/features/conversationsSlice/selectors';

const SearchResult = () => {
  const { t } = useTranslation();
  const {
    contacts,
    business,
    showContacts,
    setShowContacts,
    showBusiness,
    setShowBusiness,
    showConversations,
    setShowConversations,
  } = useContext(HeaderContext);
  const [contactsLength, setContactsLength] = useState(0);
  const [businessLength, setBusinessLength] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  const conversations = useSelector(selectConversations);
  const conversationsAmout = useSelector(getConversationsAmount);

  const handleToggle = (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    setDisableStates: Array<React.Dispatch<React.SetStateAction<boolean>>>
  ) => {
    setState(true);
    setDisableStates.forEach((setDisableState) => setDisableState(false));
  };

  const handleClickAll = () => {
    setShowContacts(false);
    setShowBusiness(false);
    setShowConversations(false);
  };

  useEffect(() => {
    const cLength = contacts?.length || 0;
    const bLength = business?.length || 0;
    setContactsLength(cLength);
    setBusinessLength(bLength);
    setTotalLength(cLength + bLength + conversationsAmout);
  }, [contacts, business, conversationsAmout]);

  return (
    <Box
      p={2}
      maxHeight="50vh"
      width="50vw"
      paddingBottom={1}
      sx={{
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.dropdownBox,
        overflow: 'auto',
      }}
    >
      {/* filtro grupo etiquetas */}
      <Box display="flex" flexWrap="wrap" mb={2}>
        {/* filtro grupo Todos */}
        <Tags
          focusVisible={!showContacts && !showBusiness && !showConversations}
          title={t('inputSearch.all', { count: totalLength })}
          handleClick={() => handleClickAll()}
        />
        <Tags
          focusVisible={showConversations}
          title={t('inputSearch.allConversations', { count: conversationsAmout })}
          handleClick={() =>
            handleToggle(setShowConversations, [setShowBusiness, setShowContacts])
          }
        />
        <Tags
          focusVisible={showContacts}
          title={t('inputSearch.allContacts', { count: contactsLength })}
          handleClick={() =>
            handleToggle(setShowContacts, [setShowBusiness, setShowConversations])
          }
        />
        <Tags
          focusVisible={showBusiness}
          title={t('inputSearch.allBusiness', { count: businessLength })}
          handleClick={() =>
            handleToggle(setShowBusiness, [setShowContacts, setShowConversations])
          }
        />
      </Box>
      {/* Conversaciones */}
      {conversations?.length > 0 &&
        ((showConversations && !showBusiness && !showContacts) ||
          (!showConversations && !showBusiness && !showContacts)) && (
          <ContactList
            contactList={conversations}
            title="inputSearch.conversations"
            type="conversations"
          />
        )}
      {/* Contactos */}
      {contacts?.length > 0 &&
        ((showContacts && !showBusiness && !showConversations) ||
          (!showContacts && !showBusiness && !showConversations)) && (
          <ContactList
            contactList={contacts}
            title="inputSearch.contacts"
            type="contacts"
          />
        )}
      {/* Empresas */}
      {business?.length > 0 &&
        ((showBusiness && !showContacts && !showConversations) ||
          (!showBusiness && !showContacts && !showConversations)) && (
          <ContactList
            contactList={business}
            title="inputSearch.business"
            type="business"
          />
        )}
    </Box>
  );
};

export default SearchResult;
