import { IShortcut } from '../types/IShortcuts';

export const globalList: IShortcut[] = [
  {
    title: 'header.keyboardShortcuts.global.search',
    isCtrl: false,
    isAlt: false,
    isShift: false,
    action: 'F',
  },
  // {
  //   title: 'header.keyboardShortcuts.global.minimizeChat',
  //   isCtrl: true,
  //   isAlt: false,
  //   isShift: true,
  //   action: 'M',
  // },
];

export const conversationList: IShortcut[] = [
  {
    title: 'header.keyboardShortcuts.conversations.exit',
    isCtrl: false,
    isAlt: false,
    isShift: false,
    action: 'Escape',
  },
  {
    title: 'header.keyboardShortcuts.conversations.newConversation',
    isCtrl: false,
    isAlt: false,
    isShift: false,
    action: 'N',
  },
];

export const contactsList: IShortcut[] = [
  {
    title: 'header.keyboardShortcuts.contacts.newContact',
    isCtrl: true,
    isAlt: false,
    isShift: true,
    action: 'N',
  },
  {
    title: 'header.keyboardShortcuts.contacts.newGroup',
    isCtrl: true,
    isAlt: false,
    isShift: true,
    action: 'G',
  },
];
