import { useState } from "react";
// Redux
import { useSelector } from "react-redux";
// Space
import { selectSpaceInfo } from "redux/features/spaceSlice/spaceSlice";
// Components/ui
import { Avatar, Box, Button } from "@mui/material";
// Components
import { SpaceSelector } from "./components";
import { Theme } from "@mui/system";
import { selectedTheme } from "../../../../redux/features/themeSlice/themeSlice";

const Space = () => {
  const [open, setOpen] = useState(false);
  const infoSpace = useSelector(selectSpaceInfo);
  const onSelectedTheme: Theme = useSelector(selectedTheme);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const avatarSrc = infoSpace?.imageUrl;
  const avatarFallbackLetter = infoSpace?.name ? infoSpace.name.charAt(0).toUpperCase() : "";

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="3rem"
      height="100%"
      sx={{
        backgroundImage: onSelectedTheme.palette.background.mainGradient,

      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
        }}
      >
        <Button
          onClick={handleDrawerOpen}
          sx={{
            minWidth: '3rem',
            minHeight: '3rem',
            '&:hover': {
              backgroundColor: theme => `${theme.palette.grey[200]}30`,
              // backgroundColor: onSelectedTheme.palette.text.secondary,
              color: (theme) => theme.palette.primary.contrastText
            },
          }}>
          <Avatar
            alt={infoSpace?.name}
            src={avatarSrc}
            style={{ width: '2rem', height: '2rem', borderRadius: "50%" }}
          >
            {avatarSrc ? null : avatarFallbackLetter}
          </Avatar>
        </Button>
      </Box>
      <SpaceSelector open={open} setOpen={setOpen} />
    </Box>
  );
};

export default Space;
