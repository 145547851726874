import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import initRequestData from '../../functions/initRequestData';
// Types
import { RootState } from '../../rootReducer';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { SpaceSliceState } from './types/SpaceSliceState';
import { ISpaceInfo } from '@trii/types/dist/Spaces';
// Service
import spaceSliceService from './spaceSliceService';
import { RequestStatus } from '../../../types/reduxTypes';

const initialState: SpaceSliceState = {
  spaceInfo: null,
  URL: {
    CONVERSATIONS: '',
    USER: 'https://agent-api.trii.app/api/v1/settings/user',
    CONTACTS: '',
    NOTIFICATION: '',
  },
  status: {
    fetch: 'idle',
  },
};

export const fetchSpaceInfo = createAsyncThunk(
  'space/getSpaceInfo',
  async (_, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await spaceSliceService.fetchSpaceInfo(jwtToken);

    return response.data;
  }
);

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setSpaceInfo: (state, action: PayloadAction<ISpaceInfo>) => {
      state.spaceInfo = action.payload;
    },
    setSpaceFetchStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.fetch = action.payload;
    },
    setSpaceUrlConversations: (state, action: PayloadAction<string>) => {
      const tenantID = action.payload;
      
      //const urlAPI = state.spaceInfo.apiUrl && state.spaceInfo.apiUrl !== '' ? state.spaceInfo.apiUrl : 'https://agent-api.trii.app';

      state.URL.USER = `https://agent-api.trii.app/api/v1/settings/${tenantID}/user`;
      state.URL.CONVERSATIONS = `https://agent-api.trii.app/api/v1/conversations/${tenantID}`;
      // state.URL.CONTACTS = `${urlAPI}/api/v1/contacts/${tenantID}/Search`;
      state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${tenantID}/Search`;
      state.URL.NOTIFICATION = `https://agent-api.trii.app/api/v1/notifications/${tenantID}`;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpaceInfo.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder.addCase(
      fetchSpaceInfo.fulfilled,
      (state, action: PayloadAction<ISpaceInfo>) => {
        state.spaceInfo = action.payload;
        state.URL.USER = `https://agent-api.trii.app/api/v1/settings/${action.payload.id}/user`;
        state.URL.CONVERSATIONS = `https://agent-api.trii.app/api/v1/conversations/${action.payload.id}`;
        state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${action.payload.id}/Search`;
        state.URL.NOTIFICATION = `https://agent-api.trii.app/api/v1/notifications/${action.payload.id}`;

        // if (action.payload.apiUrl) {
        //   state.URL.CONTACTS = `${action.payload.apiUrl}/api/v1/Contact/Search`;
        // } else if (action.payload.id) {
        //   state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${action.payload.id}/Search`;
        // }

        state.status.fetch = 'succeeded';
      }
    );
  },
});

export const {
  setSpaceInfo,
  setSpaceFetchStatus,
  setSpaceUrlConversations,
  // setContactsUrl,
} = spaceSlice.actions;

const selectSpaceState = (state: RootState) => state.Space;
export const selectSpaceInfo = createSelector(
  selectSpaceState,
  (space) => space.spaceInfo
);

export default spaceSlice.reducer;
