// Components
import { Box, CircularProgress } from '@mui/material';

const NotificationProgress = () => {
  return (
    <Box
      display="flex"
      height={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <CircularProgress />
    </Box>
  );
};

export default NotificationProgress;
