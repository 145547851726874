// Components/ui
import { Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

type SettingsSectionProps = {
  children: React.ReactNode;
  title: string;
};

const SettingsSection = ({ children, title }: SettingsSectionProps) => {
  return (
    <div>
      <Typography variant="h6">{title}</Typography>
      <Divider />
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default SettingsSection;
