import { UserStatus } from "@trii/types/dist/Users";

const statusSelector = [
  {
    key: UserStatus.ONLINE,
    label: 'dropdownMenuProfile.status.online'
  },
  {
    key: UserStatus.AWAY,
    label: 'dropdownMenuProfile.status.away'
  },
  {
    key: UserStatus.BUSY,
    label: 'dropdownMenuProfile.status.busy'
  },
  {
    key: UserStatus.OFFLINE,
    label: 'dropdownMenuProfile.status.offline'
  }
]

export default statusSelector;