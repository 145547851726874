import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
// Types
import PoolSliceState from './types/PoolSliceState';
import { RootState } from '../../rootReducer';

const initialState: PoolSliceState = {
  pool: new CognitoUserPool({
    UserPoolId: 'sa-east-1_81dIzrFFU',
    ClientId: '12rg9i8fotah5dt9ms8mfmn2mq',
    Storage: new CookieStorage({
      secure: false,
      domain: window.location.host.replace(':8080', '').replace('agent.', ''),
    }),
  }),
};

const poolSlice = createSlice({
  name: 'pool',
  initialState,
  reducers: {
    setPool: (state, action: PayloadAction<CognitoUserPool>) => {
      state.pool = action.payload;
    },
  },
});

export const { setPool } = poolSlice.actions;
export const selectPool = (state: RootState) => state.Pool.pool;

export default poolSlice.reducer;
