// Translation
import { useTranslation } from 'react-i18next';
// Components
import { Box, Button } from '@mui/material';
// Redux
import { UpdateNotification } from 'redux/features/notificationSlice/types/UpdateNotification';
import {
  clearNotifications,
  fetchUpdateNotification,
} from 'redux/features/notificationSlice/notificationSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const ClearButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleCloseNotifications = () => {
    const data: UpdateNotification = {
      notificationId: '',
      action: 'DeleteAll',
    };

    try {
      dispatch(fetchUpdateNotification(data));
      dispatch(clearNotifications());
    } catch (error) {
      console.error('Error clearing ALL notifications', error);
    }
  };

  return (
    <Box display="flex" justifyContent="end" mr={2}>
      <Button
        variant="text"
        size="small"
        sx={{
          fontSize: '12px',
        }}
        onClick={handleCloseNotifications}
      >
        {t('notification.clearAll')}
      </Button>
    </Box>
  );
};

export default ClearButton;
