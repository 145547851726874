import { useState } from 'react';

type DrawerContent = 'notifications' | 'settings';

export type UseDrawerContentReturn = {
  content: DrawerContent;
  showNotifications: () => void;
  showSettings: () => void;
};

const useDrawerContent = (): UseDrawerContentReturn => {
  const [content, setContent] = useState<DrawerContent>('notifications');

  const showNotifications = () => setContent('notifications');
  const showSettings = () => setContent('settings');

  return { content, showNotifications, showSettings };
};

export default useDrawerContent;
