import { Box, Button, CircularProgress, useTheme } from '@mui/material';
export function SaveSettingsButton({
  notificationsconfigUpdateStatus,
  handleSave,
  t,
}) {
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      sx={{
        width: '36rem',
        bottom: 0,
        position: 'fixed',
        borderTop: '1px solid',
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.default,
        justifyContent: 'flex-end',
      }}
    >
      <Button
        disabled={notificationsconfigUpdateStatus === 'loading'}
        onClick={handleSave}
        variant="contained"
        color="primary"
        sx={{
          alignSelf: 'flex-end',
          backgroundColor: `${theme.palette.primary.main} !important`,
          margin: '10px',
        }}
      >
        {notificationsconfigUpdateStatus === 'loading' ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          t('global.save')
        )}
      </Button>
    </Box>
  );
}
