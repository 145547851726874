import { createContext, useState, ReactNode } from 'react';
// Redux
import { fetchContacts } from 'redux/features/contactsSlice/contactsSlice';
// Helpers
import getNewContactFetchData from 'utils/getNewContactFetchData';
// Custom hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Types
import { HeaderContextType } from './types/HeaderContextType';
import { IBusiness, IContact } from '@trii/types/dist/Contacts';
import {
  fetchUpdateNotification,
  getNewNotifications,
} from 'redux/features/notificationSlice/notificationSlice';
import { UpdateUserStatus } from 'redux/features/userSlice/types/UpdateUserStatus';
// Slice
import { resetContacts } from 'redux/features/contactsSlice/contactsSlice';
import { UpdateNotification } from 'redux/features/notificationSlice/types/UpdateNotification';
import { fetchUpdateUserStatus } from 'redux/features/userSlice/userSlice';
import ContactsFetchData from 'types/ContactsFetchData';
import {
  fetchConversations,
  resetConversations,
} from 'redux/features/conversationsSlice/conversationsSlice';

export const HeaderContext = createContext<HeaderContextType>({
  closePopover: () => {},
  isPopoverOpen: false,
  setIsPopoverOpen: () => {},
  contactSearchField: '',
  setContactSearchField: () => {},
  contacts: [],
  setContacts: (contacts: IContact[]) => {},
  business: [],
  setBusiness: (business: IBusiness[]) => {},
  clearSearchField: () => {},
  anchorEl: null,
  setAnchorEl: () => {},
  showContacts: false,
  setShowContacts: () => {},
  showBusiness: false,
  setShowBusiness: () => {},
  showConversations: false,
  setShowConversations: () => {},
  handleClear: () => {},
  handleClose: () => {},
  // Api Functions
  searchContacts: () => {},
  updateNotification: () => {},
  updateUserStatus: () => {},
});

type HeaderProviderProps = {
  children: ReactNode;
};

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const dispatch = useAppDispatch();
  const [contactSearchField, setContactSearchField] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [business, setBusiness] = useState<IBusiness[]>([]);
  const [showContacts, setShowContacts] = useState(false);
  const [showBusiness, setShowBusiness] = useState(false);
  const [showConversations, setShowConversations] = useState(false);

  const handleClear = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Escape') {
      if (contactSearchField === '') {
        setAnchorEl(null);
      } else clearSearchField();
    }
  };

  const clearSearchField = () => {
    setContacts([]);
    setBusiness([]);
    dispatch(resetConversations());
    setContactSearchField('');
    setShowBusiness(false);
    setShowContacts(false);
    setShowConversations(false);
    dispatch(resetContacts());
    closePopover();
  };

  const closePopover = () => {
    setAnchorEl(null);

    setIsPopoverOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    clearSearchField();
  };

  // Api Functions
  const getContacts = async (list: ContactsFetchData) => {
    const response = await dispatch(fetchContacts(list));
    return response.payload;
  };
  const getBusiness = async (list: ContactsFetchData) => {
    const response = await dispatch(fetchContacts(list));
    return response.payload;
  };
  const getConversations = async (nameFilterValue) => {
    await dispatch(fetchConversations(nameFilterValue));
  };

  const searchContacts = async (value: string) => {
    const fetchContactList = getNewContactFetchData(value, 'IContact');
    const fetchBusinessList = getNewContactFetchData(value, 'IBusiness');
    const response = await Promise.all([
      getContacts(fetchContactList),
      getBusiness(fetchBusinessList),
      getConversations(value),
    ]);

    const newContacts = response[0] as IContact[];
    const newBusiness = response[1] as IBusiness[];

    setContacts(newContacts);
    setBusiness(newBusiness);
  };

  const updateNotification = (data: UpdateNotification) => {
    dispatch(fetchUpdateNotification(data));
    dispatch(getNewNotifications(data.notificationId));
  };

  const updateUserStatus = async (data: UpdateUserStatus) => {
    await dispatch(fetchUpdateUserStatus(data));
  };

  return (
    <HeaderContext.Provider
      value={{
        closePopover,
        isPopoverOpen,
        setIsPopoverOpen,
        contactSearchField,
        setContactSearchField,
        contacts,
        setContacts,
        business,
        setBusiness,
        clearSearchField,
        anchorEl,
        setAnchorEl,
        showContacts,
        setShowContacts,
        showBusiness,
        setShowBusiness,
        showConversations,
        setShowConversations,
        handleClear,
        handleClose,
        // Api Functions
        searchContacts,
        updateNotification,
        updateUserStatus,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
