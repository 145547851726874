import { useContext, useState } from "react";
// Router
import { useNavigate } from "react-router-dom";
// Components/ui
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import ChatIcon from "@mui/icons-material/Chat";
// Context
import { HeaderContext } from "components/Header/components/context/HeaderProvider";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  imageSrc: string;
  id: string;
  contactId: string;
}

const ConversationItem = ({ name, imageSrc, id, contactId }: Props) => {
  const { handleClose } = useContext(HeaderContext);

  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  function stringAvatar(name: string) {
    if (name !== undefined) {
      const splittedName = name?.split(" ");

      if (splittedName) {
        const firstLetter = splittedName[0][0];

        switch (splittedName.length) {
          case 1:
            return {
              children: `${firstLetter}`,
            };
          case 2:
            const secondLetter = splittedName[1][0];
            return {
              children: `${firstLetter}${secondLetter}`,
            };
          default:
            return {
              children: `${firstLetter}`,
            };
        }
      }
    } else {
      return (
        <>
          <CircularProgress
            sx={{
              marginLeft: 5,
              marginRight: 5,
              height: 54,
              width: 54,
              fontSize: "14px",
              color: "lightgray",
            }}
          />
        </>
      );
    }
  }

  const handleNavigateToContact = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/a/contacts/contacts/${contactId}`);

    handleClose();
  };

  const handleNavigateToConversation = () => {
    navigate(`/a/conversations/conversations?conversationId=${id}`);

    handleClose();
  };
  const { t } = useTranslation();

  return (
    <Button
      variant="text"
      size="small"
      sx={{
        minWidth: "200px",
        justifyContent: "start",
        px: 1,
        minHeight: "55px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box display="flex" alignItems="center">
        <Avatar
          {...stringAvatar(name)}
          alt=""
          src={imageSrc}
          sx={{
            width: 32,
            height: 32,
            bgcolor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.secondary,
          }}
        />
        <Box ml={1.5} display="flex" flexDirection="column" flexGrow={1}>
          <Typography
            color="text.primary"
            variant="body2"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Typography>
          <Box display="flex" alignItems="center" mt={0.5}>
            {isHovered && (
              <>
                <IconButton size="small" onClick={handleNavigateToConversation}>
                  <Tooltip
                    title={t("inputSearch.openInConversations")}
                    placement="top"
                    enterDelay={2000}
                  >
                    <ChatIcon style={{ fontSize: "1rem" }} />
                  </Tooltip>
                </IconButton>

                <IconButton size="small" onClick={handleNavigateToContact}>
                  <Tooltip
                    title={t("inputSearch.openInContacts")}
                    placement="top"
                    enterDelay={2000}
                  >
                    <FontAwesomeIcon
                      icon={faAddressBook}
                      style={{ fontSize: "1rem" }}
                    />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Button>
  );
};

export default ConversationItem;
