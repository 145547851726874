import { useEffect } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  fetchDevices,
  selectDevices,
} from 'redux/features/notificationSlice/notificationSlice';
// Components/ui
import { DeviceItem } from './components';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const Devices = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const devices = useSelector(selectDevices);

  useEffect(() => {
    dispatch(fetchDevices());

    return () => {};
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        overflowY: 'auto',
      }}
    >
      <Typography variant="h6">{t('notification.yourDevices')}</Typography>
      {devices.map((device, index) => (
        <DeviceItem key={index} data={device} />
      ))}
    </Box>
  );
};

export default Devices;
