import { useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
import {
  fetchNotifications,
  selectNotifications,
  selectNotificationsFetchStatus,
  updateNotificationsSettings,
} from 'redux/features/notificationSlice/notificationSlice';
import { selectedTheme } from 'redux/features/themeSlice/themeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Types
import type AnchorState from '../../types/AnchorState';
import { Theme } from '@mui/system';
// Components/ui
import {
  Header,
  NotificationItem,
  NotificationProgress,
  Settings,
} from './components';
import { Divider, Drawer, Box } from '@mui/material';
// Hooks
import useDrawerContent from './hooks/useDrawerContent';

type Props = {
  anchor: string;
  toggleDrawer: (anchor: string, open: boolean) => (event: any) => void;
  state: AnchorState;
};

const NotificationDrawer = ({ toggleDrawer, anchor, state }: Props) => {
  const dispatch = useAppDispatch();

  const notifications = useSelector(selectNotifications);
  const notificationsFetchStatus = useSelector(selectNotificationsFetchStatus);
  const onSelectedTheme: Theme = useSelector(selectedTheme);

  const isLoading = notificationsFetchStatus === 'loading';

  const drawerContent = useDrawerContent();

  function handleSaveNotificationSettings() {
    dispatch(updateNotificationsSettings());
  }

  useEffect(() => {
    if (state.right) {
      dispatch(fetchNotifications());
    }
  }, [state]);

  return (
    <Drawer
      anchor={'right'}
      open={state[anchor]}
      onClose={toggleDrawer(anchor, false)}
      sx={{
        overflowX: 'hidden',
        '& .MuiDrawer-paper': {
          backgroundColor: onSelectedTheme.palette.background.default,
          backgroundImage: 'unset',
          // height: drawerContent.content === 'settings' ? '95%' : '100%',
          height: '100%',
        },
        '& .MuiCard-root:hover': {
          cursor: 'pointer',
          backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
          color: onSelectedTheme.palette.text.primary,
        },
      }}
    >
      <Box
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500,
        }}
        role="presentation"
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Header
          drawerContent={drawerContent}
          onClose={toggleDrawer(anchor, false)}
        />
        <Divider sx={{ borderColor: onSelectedTheme.palette.divider }} />
        {drawerContent.content === 'notifications' && (
          <>
            {isLoading ? (
              <NotificationProgress />
            ) : (
              notifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))
            )}
          </>
        )}
        {drawerContent.content === 'settings' && (
          <Settings handleSave={handleSaveNotificationSettings} />
        )}
      </Box>
    </Drawer>
  );
};

export default NotificationDrawer;
