import getRequestConfig from 'redux/functions/getRequestConfig';
import axios from 'axios';
// Types
import { FetchConversationsReturnData } from './types/Pagination';

async function fetchConversations(
  jwtToken: string,
  URL_CONVERSATIONS: string,
  nameFilterValue: string
) {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  const response = await axios.post<FetchConversationsReturnData>(
    `${URL_CONVERSATIONS}/Search`,
    {
      filter: [
        {
          column: 'name',
          condition: '',
          value: nameFilterValue,
        },
      ],
    },
    config
  );

  return response.data;
}

export default { fetchConversations };
