export type NotificationsConfigItemKey =
  | 'newConversation'
  | 'transferIn'
  | 'newMessage'
  | 'responseTimeOut'
  | 'reminders'
  | 'newTicketAssigned'
  | 'newContactMessage'
  | 'firstResponseTimeOut'
  | 'resolutionTimeOut';

export function isNotificationsConfigItemKey(
  key: any
): key is NotificationsConfigItemKey {
  return [
    'newConversation',
    'transferIn',
    'newMessage',
    'responseTimeOut',
    'reminders',
    'newTicketAssigned',
    'newContactMessage',
    'firstResponseTimeOut',
    'resolutionTimeOut',
  ].includes(key);
}
