import {
  Avatar,
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popover,
} from "@mui/material";

import { MenuProfile } from "./components";
import { useState } from "react";
// Types
import { UserTrii } from "@trii/types/dist/Users";
// Utils
import { getBadgeStatus } from "./utils/getBadgeStatus";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StatusSelector } from "./components/MenuProfile/components";

interface Props {
  user: UserTrii;
}

const DropdownProfile = ({ user }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenStatusModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} disableRipple={isHovered}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Avatar
            alt={user?.display_name}
            src={user?.imageUrl}
            style={{ border: "1px solid #ced1d5" }}
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
          <Badge
            color="success"
            overlap="circular"
            variant="dot"
            badgeContent={isHovered ? <KeyboardArrowDownIcon /> : ""}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: !isHovered
                  ? getBadgeStatus(user?.status)
                  : "#ffffff",
                right: "5.5px",
                bottom: "-10px",
              },
              "& :hover": {
                cursor: "pointer",
              },
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleOpenStatusModal}
          ></Badge>
        </Box>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          zIndex: 99,
          marginTop: "10px !important",
          "&.MuiPopover-root > .MuiPaper-root": {
            boxShadow: "0px 0px 2px 1px rgb(190 189 189)",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <MenuProfile user={user} />
          </Paper>
        </ClickAwayListener>
      </Popover>
      <StatusSelector
        open={openModal}
        onClose={handleCloseStatusModal}
        userStatus={user?.status}
        userId={user?.uid}
      />
    </>
  );
};

export default DropdownProfile;
