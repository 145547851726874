import AppList from './types/AppList';
import TriiCalendarIcon from './AppsIcon/utils/images/TriiCalendar.svg';
import TriiCallIcon from './AppsIcon/utils/images/TriiCall.svg';
import TriiChatIcon from './AppsIcon/utils/images/TriiChat.svg';
import TriiCollectionIcon from './AppsIcon/utils/images/TriiCollection.svg';
import TriiContactsIcon from './AppsIcon/utils/images/TriiContacts.svg';
import TriiCRMIcon from './AppsIcon/utils/images/TriiCRM.svg';
import TriiMarketingIcon from './AppsIcon/utils/images/TriiMarketing.svg';
import TriiServiceIcon from './AppsIcon/utils/images/TriiService.svg';
import TriiTeamIcon from './AppsIcon/utils/images/TriiTeam.svg';
import TriiTicketsIcon from './AppsIcon/utils/images/TriiTickets.svg';

const appList: AppList[] = [
  {
    id: 1,
    name: 'dropdownAppList.TriiChat',
    icon: TriiTeamIcon,
    url: '/a/chat',
    disabled: '',
  },
  {
    id: 2,
    name: 'dropdownAppList.TriiConversations',
    icon: TriiChatIcon,
    url: '/a/conversations',
    disabled: '',
  },
  {
    id: 3,
    name: 'dropdownAppList.TriiCalls',
    icon: TriiCallIcon,
    url: '/a/calls',
    disabled: '',
  },
  {
    id: 4,
    name: 'dropdownAppList.TriiDebtCollection',
    icon: TriiCollectionIcon,
    url: '/a/debtcollection',
    disabled: '',
  },
  {
    id: 5,
    name: 'dropdownAppList.TriiMarketing',
    icon: TriiMarketingIcon,
    url: '/a/marketing',
    disabled: '',
  },
  {
    id: 6,
    name: 'dropdownAppList.TriiRepair',
    icon: TriiServiceIcon,
    url: '/a/repair',
    disabled: '',
  },
  {
    id: 7,
    name: 'dropdownAppList.TriiTickets',
    icon: TriiTicketsIcon,
    url: '/a/tickets',
    disabled: '',
  },
  {
    id: 8,
    name: 'dropdownAppList.TriiContacts',
    icon: TriiContactsIcon,
    url: '/a/contacts',
    disabled: '',
  },
  {
    id: 9,
    name: 'dropdownAppList.TriiCalendar',
    icon: TriiCalendarIcon,
    url: '/a/calendar/calendar',
    disabled: '',
  },
  {
    id: 10,
    name: 'dropdownAppList.TriiCRM',
    icon: TriiCRMIcon,
    url: '/a/CRM',
    disabled: 'disabled',
  },
];

export default appList;
