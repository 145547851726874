import React from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Button } from '@mui/material';
import { style } from './style';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = {
  showSettings: () => void;
  showDevices: () => void;
  currentlyShowing: 'settings' | 'devices';
};

const NavigationButton: React.FC<Props> = ({
  showSettings,
  showDevices,
  currentlyShowing,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (currentlyShowing === 'settings') {
      showDevices();
    } else {
      showSettings();
    }
  };

  const buttonText =
    currentlyShowing === 'settings'
      ? t('notification.devices')
      : t('notification.settings');
  const buttonIcon =
    currentlyShowing === 'settings' ? <ArrowForwardIcon /> : <ArrowBackIcon />;

  return (
    <Button sx={style.button} onClick={handleClick} variant="outlined">
      {buttonText}
      {buttonIcon}
    </Button>
  );
};

export default NavigationButton;
