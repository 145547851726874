import axios from 'axios';
// Types
import { IContact } from '@trii/types/dist/Contacts';
import ContactsFetchData from 'types/ContactsFetchData';
// Helper
import getRequestConfig from 'redux/functions/getRequestConfig';

const fetch = async (
  jwtToken: string,
  URL: string,
  body: ContactsFetchData
): Promise<IContact[]> => {
  const config = getRequestConfig.basic(jwtToken);

  const response = await axios.post(URL, body, config);

  return response.data.contactos;
};

const contactService = { fetch };

export default contactService;
