// Header.tsx
import React, { useEffect, useState, useContext, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { AppsDropdown, DropdownProfile, KeyboardShortcutsHelp, Notifications, SearchInput, Space, updatePaths } from "./components";
import { useSelector, useDispatch } from "react-redux";
import { setPool } from "redux/features/poolSlice/poolSlice";
import { selectUser, getUser, setUser, setUserStatus } from "../../redux/features/userSlice/userSlice";
import { fetchSpaceInfo, setSpaceInfo, setSpaceFetchStatus, setSpaceUrlConversations } from "../../redux/features/spaceSlice/spaceSlice";
import { toggleTheme } from "redux/features/themeSlice/themeSlice";
import { fetchNotificationsSettings } from "redux/features/notificationSlice/notificationSlice";
import { setFirebaseToken } from "redux/features/sessionSlice/sessionSlice";
import RootProps from "../../types/RootProps";
import { ThemeProvider, createTheme } from "@mui/system";
import { selectedTheme } from "../../redux/features/themeSlice/themeSlice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { DataContext } from "context/contextApi";
import MuiComponentsConfig from "style/MuiComponentesConfig";

interface Props {
  authenticated: boolean;
  rootProps: RootProps;
}

const Header = ({ authenticated, rootProps }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onSelectedTheme = useSelector(selectedTheme);
  const user = useSelector(selectUser);
  const themeRedux = useSelector(selectedTheme);
  const theme = useMemo(() => MuiComponentsConfig(themeRedux), [themeRedux]);
  const select = JSON.parse(localStorage.getItem("select")) || "espanol";
  const location = useLocation();

  const [value, setValue] = useState(select);
  const [windowPath, setWindowPath] = useState("");
  const [modulePath, setModulePath] = useState("");

  useEffect(() => {
    updatePaths(location.pathname, t, setModulePath, setWindowPath);
  }, [location, value, select, t]);

  useEffect(() => {
    if (rootProps.firebaseToken) {
      dispatch(setFirebaseToken(rootProps.firebaseToken));
    }

    if (rootProps.spaceInfo && rootProps.userInfo && rootProps.pool) {
      const { spaceInfo, userInfo, pool } = rootProps;

      dispatch(setPool(pool));
      dispatch(setSpaceInfo(spaceInfo));
      dispatch(setSpaceFetchStatus("succeeded"));
      dispatch(setSpaceUrlConversations(spaceInfo.id));
      dispatch(setUser(userInfo));
      dispatch(toggleTheme(userInfo.theme));
    } else {
      dispatch(fetchSpaceInfo());
      dispatch(getUser());
    }

    dispatch(fetchNotificationsSettings());
  }, [dispatch, rootProps]);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "userStatus") {
        const newStatus = Number(JSON.parse(e.newValue));
        dispatch(setUserStatus(newStatus));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);
  const { isMobile } = useContext(DataContext);

  return (
    <ThemeProvider theme={theme}>
      {authenticated ? (
        <Box
          sx={{
            //@ts-ignore
            backgroundColor: onSelectedTheme.palette.background.default,
            boxShadow: "rgb(0 0 0 / 25%) 0px 1px 6px 0px",
            maxHeight: "3rem",
            height: "3rem",
            zIndex: 3,
            position: "sticky",
            display: "flex",
            alignItems: "center",
            borderBottom: `${
              //@ts-ignore
              onSelectedTheme.palette.mode === "dark" &&
              //@ts-ignore
              `1px solid ${onSelectedTheme.palette.divider}`
            }`,
            minWidth: isMobile ? "fit-content" : "auto",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              height="100%"
              sx={{
                width: isMobile ? "100vw" : "auto",
              }}
            >
              <Space />
              <Box sx={{ width: "200px" }}>
                <Typography
                  sx={{
                    //@ts-ignore

                    color: onSelectedTheme.palette.text.primary,
                    paddingLeft: "32px",
                    fontWeight: "bold",
                    marginBottom: "-5px",
                  }}
                  variant="subtitle1"
                >
                  {modulePath}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    //@ts-ignore

                    color: onSelectedTheme.palette.text.primary,
                    paddingLeft: "48px",
                    fontWeight: "bold",
                    fontSize: "0.70rem",
                  }}
                >
                  {windowPath}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: isMobile ? "100vw" : "auto" }}>
              <SearchInput />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                flexFlow: "row",
                paddingRight: "32px",
              }}
            >
              <KeyboardShortcutsHelp />
              <Notifications />
              <AppsDropdown />
              <DropdownProfile user={user} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <NavLink to="/login">
            <button color="inherit">
              <Typography
                variant="body2"
                //@ts-ignore
                color={onSelectedTheme.palette.text.primary}
              >
                {t("global.login")}
              </Typography>
            </button>
          </NavLink>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default Header;
