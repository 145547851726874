import { useContext, useState, useEffect } from 'react';
// Redux
import { selectedTheme } from 'redux/features/themeSlice/themeSlice';
import { useSelector } from 'react-redux';
// Components
import { Box, Card, IconButton, Typography, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// Types
import { Theme } from '@mui/system';
import { INotification } from '@trii/types/dist/Common/Notifications';
// Context
import { HeaderContext } from 'components/Header/components/context/HeaderProvider';
import { UpdateNotification } from 'redux/features/notificationSlice/types/UpdateNotification';
// Moment
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

type Props = {
  notification: INotification;
};

const NotificationItem = ({ notification }: Props) => {
  const {
    updateNotification
  } = useContext(HeaderContext);
  const [show, setShow] = useState(false);
  const onSelectedTheme: Theme = useSelector(selectedTheme);

  const handleCloseNotification = (notificationId: string) => {
    const data = {
      notificationId,
      action: 'Read'
    } as UpdateNotification
    updateNotification(data)
  }

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Card
        variant="outlined"
        sx={{
          py: 1,
          px: 2,
          border: 0,
          borderBottom: `1px solid ${onSelectedTheme.palette.divider}`,
          borderRadius: 0,
          backgroundColor: onSelectedTheme.palette.dropdownBox,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex">
            <CheckBoxIcon color="success" />
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                color: onSelectedTheme.palette.text.primary,
              }}
              ml={1}
            >
              {notification.title}
            </Typography>
          </Box>
          <IconButton
            onClick={() => handleCloseNotification(notification.id)}
          >
            <CloseIcon
              sx={{
                fontSize: '14px',
                color: onSelectedTheme.palette.text.primary
              }}
            />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          mt={2}
          sx={{ color: onSelectedTheme.palette.text.primary }}
        >
          {notification.description}
        </Box>
        <Box
          display="flex"
          justifyContent="end"
          alignItems="center"
          mt={2}
          sx={{ color: onSelectedTheme.palette.text.primary }}
        >
          {moment(notification.createdAt).format(DATE_FORMAT)}
        </Box>
      </Card>
    </Fade>
  );
};

export default NotificationItem;
