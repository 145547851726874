import React, { createContext, useEffect, useState } from "react"; //eslint-disable-line
import PropTypes from "prop-types";
import { UserAccount } from "../Models/UserAccount";
// Types
import { ContextApi } from "./types/ContextApi";

export const DataContext = createContext<ContextApi>({
  pageLoaded: false,
  isMobile: false,
  setIsMobile: () => { },
  usuario: null,
  setUsuario: () => { },
  userProfile: null,
  setUserProfile: () => { },
  isEdit: false,
  setIsEdit: () => { },
  editAccount: null,
  setEditAccount: () => { },
  openInputPopup: false,
  setOpenInputPopup: () => { },
  openDeletePopup: false,
  setOpenDeletePopup: () => { },
  showPageSideBar: false,
  setShowPageSideBar: () => { },
  openPopup: false,
  setOpenPopup: () => { },
});

const DataProvider = (props) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [usuario, setUsuario] = useState<UserAccount>();
  const [userProfile, setUserProfile] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editAccount, setEditAccount] = useState<any>(null);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [showPageSideBar, setShowPageSideBar] = useState<boolean>(false);
  const [openInputPopup, setOpenInputPopup] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  React.useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      setIsMobile(true);
    } else {
      // false for other device
      setIsMobile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataContext.Provider
      value={{
        pageLoaded,
        isMobile,
        setIsMobile,
        usuario,
        setUsuario,
        userProfile,
        setUserProfile,
        isEdit,
        setIsEdit,
        editAccount,
        setEditAccount,
        openInputPopup,
        setOpenInputPopup,
        openDeletePopup,
        setOpenDeletePopup,
        showPageSideBar,
        setShowPageSideBar,
        openPopup,
        setOpenPopup,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node,
};
export default DataProvider;
