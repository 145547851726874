// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, IconButton, styled, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Theme } from '@mui/system';
import { useSelector } from 'react-redux';
import {selectedTheme} from "../../../../../../../../redux/features/themeSlice/themeSlice";

interface Props {
  handleDrawerClose: () => void;
}

const Header = ({ handleDrawerClose }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));
  const onSelectedTheme: Theme = useSelector(selectedTheme)

  return (
    <DrawerHeader sx={{ justifyContent: 'space-between' }}>
      <Typography variant="subtitle2" sx={{color: onSelectedTheme.palette.text.primary}}>{t('navBar.selectWorkspace')}</Typography>
      <IconButton
        onClick={handleDrawerClose}
        aria-label="close"
        size={'small'}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {theme.direction === 'ltr' ? (
          <ChevronLeft
            sx={{
              fontSize: '20px',
            }}
          />
        ) : (
          <ChevronRight
            sx={{
              fontSize: '20px',
            }}
          />
        )}
      </IconButton>
    </DrawerHeader>
  );
};

export default Header;
