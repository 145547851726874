// Redux
import { useSelector } from 'react-redux';
// Components/ui
import {
  Chip,
  styled
} from '@mui/material';
import { Theme } from '@mui/system';
// Slice
import { selectedTheme } from 'redux/features/themeSlice/themeSlice';

interface TagsProps {
  focusVisible: boolean;
  title: string;
  handleClick: () => void;
}

const Tags = ({
  focusVisible,
  title,
  handleClick,
}) => {
  const onSelectedTheme: Theme = useSelector(selectedTheme);

  const StyledChip = styled(Chip)(({ theme }) => ({
    'backgroundColor': onSelectedTheme.palette.cellHeader,
    'color': onSelectedTheme.palette.text.primary,
    '&.Mui-focusVisible': {
      backgroundColor: onSelectedTheme.palette.action.disabled,
      color: onSelectedTheme.palette.text.primary,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.disabled,
      color: onSelectedTheme.palette.text.primary,
    },
    '&:active': {
      boxShadow: 'none',
    },
  }));

  return (
    <StyledChip
      className={focusVisible ? 'Mui-focusVisible bold noHover' : ''}
      label={title}
      sx={{
        mr: 1,
        mb: 1,
        color: onSelectedTheme.palette.mode === 'dark' ? (
          '#bebebe'
        ) : (
          onSelectedTheme.palette.text.primary
        )
      }}
      onClick={handleClick}
    />
  );
};

export default Tags;
