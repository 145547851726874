import axios from 'axios';
// Types
import {
  INotification,
  IUserNotificationsConfig,
} from '@trii/types/dist/Common/Notifications';
// Utils
import getRequestConfig from 'redux/functions/getRequestConfig';
import { UpdateNotification } from './types/UpdateNotification';
import { IDevice } from '@trii/types/dist/Accounts';

const fetch = async (
  jwtToken: string,
  URL_NOTIFICATIONS: string
): Promise<INotification[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<INotification[]>(
    `${URL_NOTIFICATIONS}/mynotifications`,
    config
  );

  return response.data;
};

const fetchUpdateNotification = async (
  jwtToken: string,
  URL_NOTIFICATIONS: string,
  data: UpdateNotification
) => {
  const config = getRequestConfig.basic(jwtToken);
  const dataJSON = JSON.stringify(data);
  const response = await axios.put(
    `${URL_NOTIFICATIONS}/mynotifications`,
    dataJSON,
    config
  );

  return response.data;
};

const fetchSettings = async (
  jwtToken: string,
  URL_NOTIFICATIONS: string
): Promise<IUserNotificationsConfig> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<IUserNotificationsConfig>(
    `${URL_NOTIFICATIONS}/userConfig`,
    config
  );

  return response.data;
};

const saveSettings = async (
  jwtToken: string,
  URL_NOTIFICATIONS: string,
  data: IUserNotificationsConfig
): Promise<IUserNotificationsConfig> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const dataJSON = JSON.stringify(data);
  const response = await axios.post<IUserNotificationsConfig>(
    `${URL_NOTIFICATIONS}/userConfig`,
    dataJSON,
    config
  );

  return response.data;
};

const fetchDevices = async (jwtToken: string): Promise<IDevice[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<IDevice[]>(
    'https://account.trii.app/api/v1/Devices',
    config
  );

  return response.data;
};

export default {
  fetch,
  fetchUpdateNotification,
  fetchSettings,
  saveSettings,
  fetchDevices,
};
