// Translations
import { useTranslation } from 'react-i18next'
// Components/ui
import { Box, Chip, Typography } from "@mui/material";

interface Props {
  title: string;
  isCtrl: boolean;
  isAlt: boolean;
  isShift: boolean;
  action: string;
}

const Shortcut = ({
  title,
  isCtrl,
  isAlt,
  isShift,
  action,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        // width: '100%',
        // height: '100%',
        padding: '1rem',
        borderRadius: '0.5rem',
        border: theme => `1px solid ${theme.palette.divider}`,
        boxShadow: theme => theme.shadows[1],
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme => theme.palette.text.primary,
          width: '100%',
        }}
      >
        {t(title)}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 1,
          width: '100%',
          height: '100%',
        }}
      >
        {
          isCtrl && (
            <Chip
              label="Ctrl"
              sx={{
                color: theme => theme.palette.text.primary,
                borderRadius: '0.5rem',
              }}
            />
          )
        }
        {
          isAlt && (
            <Chip
              label="Alt"
              sx={{
                color: theme => theme.palette.text.primary,
                borderRadius: '0.5rem',
              }}
            />
          )
        }
        {
          isShift && (
            <Chip
              label="Shift"
              sx={{
                color: theme => theme.palette.text.primary,
                borderRadius: '0.5rem',
              }}
            />
          )
        }
        <Chip
          label={action}
          sx={{
            color: theme => theme.palette.text.primary,
            borderRadius: '0.5rem',
          }}
        />
      </Box>
    </Box>
  )
}

export default Shortcut