import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import poolReducer from './features/poolSlice/poolSlice';
import sessionReducer from './features/sessionSlice/sessionSlice';
import spaceSlice from './features/spaceSlice/spaceSlice';
import spacesSlice from './features/spacesSlice/spacesSlice';
import themeReducer from './features/themeSlice/themeSlice';
import userReducer from './features/userSlice/userSlice';
import contactsReducer from './features/contactsSlice/contactsSlice';
import notificationReducer from './features/notificationSlice/notificationSlice';
import conversationsSlice from './features/conversationsSlice/conversationsSlice';

const rootReducer = combineReducers({
  Spaces: spacesSlice,
  Space: spaceSlice,
  Session: sessionReducer,
  Pool: poolReducer,
  Theme: themeReducer,
  User: userReducer,
  Contacts: contactsReducer,
  Notification: notificationReducer,
  Conversations: conversationsSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
