// Translations
import { useTranslation } from 'react-i18next'
// Components/ui
import { Box, Typography } from "@mui/material"
// Components
import { Shortcut } from './components'
// Types
import { IShortcut } from '../../types/IShortcuts'

interface Props {
  shortcutList: IShortcut[]
  title: string
}

const KeyboardShortcuts = ({
  shortcutList,
  title,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "max-content",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          width: "100%",
          position: 'sticky',
          top: 0,
          padding: '.5rem 1rem',
          color: theme => theme.palette.text.primary,
          backgroundColor: theme => theme.palette.background.default,
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        {t(title)}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "50% 50%",
          gap: ".5rem",
          padding: ".5rem 1rem",
        }}
      >
        {
          shortcutList.map((shortcut, index) => (
            <Shortcut
              key={index}
              title={shortcut.title}
              isCtrl={shortcut.isCtrl}
              isAlt={shortcut.isAlt}
              isShift={shortcut.isShift}
              action={shortcut.action}
            />
          ))
        }
      </Box>
    </Box>
  )
}

export default KeyboardShortcuts