import { SaveSettingsButton } from './components/SaveSettingsButton/SaveSettingsButton';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectNotificationsConfig,
  selectNotificationsConfigUpdateStatus,
} from 'redux/features/notificationSlice/notificationSlice';
// Components/ui
import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import {
  SettingsSection,
  SettingOptionItem,
  NavigationButton,
  Devices,
} from './components';
// Types
import { isNotificationsConfigItemKey } from 'redux/features/notificationSlice/types/NotificationsConfigItemKey';
import { useState } from 'react';

type SettingsProps = {
  handleSave: () => void;
};
const Settings = ({ handleSave }: SettingsProps) => {
  const { t } = useTranslation();

  const [currentlyShowing, setCurrentlyShowing] = useState<'settings' | 'devices'>(
    'settings'
  );

  const userNotificationSettings = useSelector(selectNotificationsConfig);
  const notificationsconfigUpdateStatus = useSelector(
    selectNotificationsConfigUpdateStatus
  );

  const { id, spaceId, userId, ...filteredNotificationSettings } =
    userNotificationSettings;

  const showSettings = () => {
    setCurrentlyShowing('settings');
  };

  const showDevices = () => {
    setCurrentlyShowing('devices');
  };

  return (
    <>
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginBottom: '55px'
        }}
      >
        <NavigationButton
          showDevices={showDevices}
          showSettings={showSettings}
          currentlyShowing={currentlyShowing}
        />
        {currentlyShowing === 'devices' && <Devices />}
        {currentlyShowing === 'settings' && (
          <>
            <SettingsSection title="Conversations">
              {Object.entries(filteredNotificationSettings.conversations).map(
                ([key, value]) => {
                  if (isNotificationsConfigItemKey(key)) {
                    return (
                      <SettingOptionItem
                        section="conversations"
                        itemKey={key}
                        item={value}
                        key={key}
                      />
                    );
                  }
                  return null;
                }
              )}
            </SettingsSection>
            <SettingsSection title="Chat">
              {Object.entries(filteredNotificationSettings.chat).map(
                ([key, value]) => {
                  if (isNotificationsConfigItemKey(key)) {
                    return (
                      <SettingOptionItem
                        section="chat"
                        itemKey={key}
                        item={value}
                        key={key}
                      />
                    );
                  }
                  return null;
                }
              )}
            </SettingsSection>
            <SettingsSection title="Tickets">
              {Object.entries(filteredNotificationSettings.tickets).map(
                ([key, value]) => {
                  if (isNotificationsConfigItemKey(key)) {
                    return (
                      <SettingOptionItem
                        section="tickets"
                        itemKey={key}
                        item={value}
                        key={key}
                      />
                    );
                  }
                  return null;
                }
              )}
            </SettingsSection>
          </>
        )}
      </Box>
      {currentlyShowing === 'settings' && (
        <SaveSettingsButton
          notificationsconfigUpdateStatus={notificationsconfigUpdateStatus}
          handleSave={handleSave}
          t={t}
        />
      )}
    </>
  );
};

export default Settings;
