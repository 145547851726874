import axios from 'axios';
// Utils
import getRequestConfig from 'redux/functions/getRequestConfig';

async function logout(URL: string, jwtToken: string, firebaseToken: string) {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  return await axios.post(
    URL,
    {
      token: firebaseToken,
    },
    config
  );
}

export default { logout };
