import { useContext } from "react"
// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Radio, Typography } from "@mui/material"
// Utils
import statusSelector from "./utils/statusSelector"
import { getBadgeStatus } from "components/Header/components/DropdownProfile/utils/getBadgeStatus"
import { UserStatus } from "@trii/types/dist/Users"
// Context
import { HeaderContext } from "components/Header/components/context/HeaderProvider"

interface Props {
  open: boolean
  onClose: () => void,
  userStatus: UserStatus,
  userId: string,
}

const StatusSelector = ({
  open,
  onClose,
  userStatus,
  userId,
}: Props) => {
  const {
    updateUserStatus
  } = useContext(HeaderContext)
  const { t } = useTranslation()

  const handleSelect = (status: UserStatus) => {
    const data = {
      uid: userId,
      status
    }
    updateUserStatus(data)
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "40vw",
          height: "max-content",
          padding: 2,
          backgroundColor: theme => theme.palette.background.default,
        }}
      >
        <Typography
          sx={{
            color: theme => theme.palette.text.primary,
            fontWeight: "bold",
            marginBottom: 2,
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
          }}
        >
          {t("dropdownMenuProfile.status.title")}
        </Typography>
        <List>
          {
            statusSelector.map((status) => (
              <ListItem
                key={status.key}
                disablePadding
              >
                <ListItemButton
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  onClick={() => handleSelect(status.key)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <ListItemIcon>
                      <Box
                        sx={{
                          height: "1rem",
                          width: "1rem",
                          borderRadius: "50%",
                          backgroundColor: getBadgeStatus(status.key),
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={t(status.label)}
                      sx={{
                        color: theme => theme.palette.text.primary,
                        fontWeight: "bold",
                      }}
                    />
                  </Box>
                  <Radio
                    checked={status.key === userStatus}
                    sx={{
                      color: theme => theme.palette.primary.main,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))
          }
        </List>
      </Box>
    </Modal>
  )
}

export default StatusSelector