// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, CircularProgress, Divider, Link, Typography } from "@mui/material";

import { OpenInNew } from "@mui/icons-material";
// Router
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";// utils
import appList from "./utils/appList";
import appDataList from "./utils/appDataList";
import { useSelector } from "react-redux";
import { selectedTheme } from "../../../../../../redux/features/themeSlice/themeSlice";
import { Theme } from "@mui/system";
import { useContext } from "react";
import { HeaderContext } from "components/Header/components/context/HeaderProvider";
import useImagePreloader from "hooks/useImagePreloader";
import { UserTrii } from "@trii/types/dist/Users";
//Icons
import TriiCalendarIcon from './images/TriiCalendar.svg';
import TriiCallIcon from './images/TriiCall.svg';
import TriiChatIcon from './images/TriiChat.svg';
import TriiCollectionIcon from './images/TriiCollection.svg';
import TriiContactsIcon from './images/TriiContacts.svg';
import TriiCRMIcon from './images/TriiCRM.svg';
import TriiMarketingIcon from './images/TriiMarketing.svg';
import TriiServiceIcon from './images/TriiService.svg';
import TriiTeamIcon from './images/TriiTeam.svg';
import TriiTicketsIcon from './images/TriiTickets.svg';
import TriiSignatureIcon from './images/TriiSignature.svg';
import TriiDataSyncIcon from './images/TriiDataSync.svg';

interface AppsMenuProps {
  user: UserTrii;
}

const AppsMenu = ({ user }: AppsMenuProps) => {
  const { t } = useTranslation();
  const imageList = appList.map((app) => app.icon);
  const imageDataList = appDataList.map((app) => app.icon);
  const { imagesPreloaded } = useImagePreloader([...imageList, ...imageDataList]);
  const navigate = useNavigate();
  const { setIsPopoverOpen } = useContext(HeaderContext);
  
  const openLinkNewTab = (event, url) => {
    setIsPopoverOpen(false);
    event.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const onSelectedTheme: Theme = useSelector(selectedTheme);

  const profileApps = [
    { name: "dropdownAppList.TriiChat", icon: TriiTeamIcon, enabled: user.profile.chat.enabled, url: '/a/chat' },
    { name: "dropdownAppList.TriiConversations", icon: TriiChatIcon, enabled: user.profile.conversations.enabled, url: '/a/conversations' },
    { name: "dropdownAppList.TriiCalls", icon: TriiCallIcon, enabled: user.profile.calls.enabled, url: '/a/calls' },
    { name: "dropdownAppList.TriiDebtCollection", icon: TriiCollectionIcon, enabled: user.profile.debtCollection.enabled, url: '/a/debtcollection' },
    { name: "dropdownAppList.TriiMarketing", icon: TriiMarketingIcon, enabled: user.profile.marketing.enabled, url: '/a/marketing' },
    { name: "dropdownAppList.TriiRepair", icon: TriiServiceIcon, enabled: user.profile.service.enabled, url: '/a/repair' },
    { name: "dropdownAppList.TriiTickets", icon: TriiTicketsIcon, enabled: user.profile.tickets.enabled, url: '/a/tickets' },
    { name: "dropdownAppList.TriiContacts", icon: TriiContactsIcon, enabled: user.profile.contacts.enabled, url: '/a/contacts' },
    { name: "dropdownAppList.TriiCalendar", icon: TriiCalendarIcon, enabled: user.profile.calendar.enabled, url: '/a/calendar'},
    { name: "dropdownAppList.TriiCRM", icon: TriiCRMIcon, enabled: user.profile.crm.enabled, url: '/a/CRM' }
  ];

  const profileDataApps = [
    { name: "dropdownAppList.TriiDataSync", icon: TriiDataSyncIcon, enabled: user.profile.datasync.enabled, url: '/a/dataSync' },
    { name: "dropdownAppList.TriiSignature", icon: TriiSignatureIcon, enabled: user.profile.signature.enabled, url: '/a/signature' },
  ];

  return (
    <>
      <Box
        sx={{
          background: onSelectedTheme.palette.background.mainGradient,
          padding: "1.25rem 1.5rem",
        }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        className="dropdown-header rounded-top"
      >
        <Typography
          variant="body1"
          sx={{ margin: 0, textAlign: "center", color: "white" }}
        >
          {t("global.applications")}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: onSelectedTheme.palette.dropdownBox,
          maxHeight: "calc(100vh - 565px)",
          minHeight: "20rem",
          width: "21rem",
          display: "grid",
          flexDirection: "column",
          placeItems: "center",
          overflowY: "auto",
        }}>
        {!imagesPreloaded ? (
          <CircularProgress
            sx={{
              color: onSelectedTheme.palette.primary.main,
              margin: "1rem auto",
            }}
          />
        ) : (
          <ul
            id="dropdownAppList"
            className="header-app-list"
            style={{ marginBottom: "10.5rem" }}
          >
            {profileApps.map((app, index) => (
              <li key={index}>
                {app.enabled ? (
                  <RouterLink to={app.url}>
                    <Link
                      color={onSelectedTheme.palette.primary.main}
                      className="header-app-list-item hover-white header-position-relative"
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            `${theme.palette.grey[200]}30`,
                          color: onSelectedTheme.palette.primary.main,
                        },
                      }}
                      onClick={closePopover}
                    >
                      <OpenInNew
                        className="header-iconCornerRight"
                        sx={{ display: "none" }}
                        onClick={(e) => openLinkNewTab(e, app.url)}
                      />
                      <span className="header-icon-stack">
                        <img alt="" src={app.icon} />
                      </span>
                      <Typography
                        variant="body2"
                        color={onSelectedTheme.palette.text.primary}
                        className="header-app-list-name"
                        mt={"0.25rem"}
                      >
                        {t(app.name)}
                      </Typography>
                    </Link>
                  </RouterLink>
                ) : (
                  <Link
                    color={onSelectedTheme.palette.primary.main}
                    className="disabled header-app-list-item hover-white header-position-relative"
                    sx={{
                      "&:hover": {
                        backgroundColor: (theme) =>
                          `${theme.palette.grey[200]}30`,
                        color: onSelectedTheme.palette.primary.main,
                      },
                    }}
                  >
                    <span className="header-icon-stack">
                      <img alt="" src={app.icon} />
                    </span>
                    <Typography
                      variant="body2"
                      color={onSelectedTheme.palette.text.primary}
                      className="header-app-list-name"
                      mt={"0.25rem"}
                    >
                      {t(app.name)}
                    </Typography>
                  </Link>
                )}
              </li>
            ))}
            <Divider
              sx={{ borderColor: onSelectedTheme.palette.divider, marginY: '1rem !important' }}
            />
            {profileDataApps.map((app, index) => (
              <li key={index}>
                {app.enabled ? (
                  <RouterLink to={app.url}>
                    <Link
                      color="inherit"
                      className="header-app-list-item hover-white header-position-relative"
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            `${theme.palette.grey[200]}30`,
                          color: onSelectedTheme.palette.primary.main,
                        },
                      }}
                    >
                      <OpenInNew
                        className="header-iconCornerRight"
                        sx={{ display: "none" }}
                        onClick={(e) => openLinkNewTab(e, app.url)}
                      />
                      <span className="header-icon-stack">
                        <img alt="" src={app.icon} />
                      </span>
                      <Typography
                        variant="body2"
                        color={onSelectedTheme.palette.text.primary}
                        className="header-app-list-name"
                        mt={"0.25rem"}
                      >
                        {t(app.name)}
                      </Typography>
                    </Link>
                  </RouterLink>
                ) : (
                  <Link
                    color="inherit"
                    className="disabled header-app-list-item hover-white header-position-relative"
                    sx={{
                      "&:hover": {
                        backgroundColor: (theme) =>
                          `${theme.palette.grey[200]}30`,
                        color: onSelectedTheme.palette.primary.main,
                      },
                    }}
                  >
                    <span className="header-icon-stack">
                      <img alt="" src={app.icon} />
                    </span>
                    <Typography
                      variant="body2"
                      color={onSelectedTheme.palette.text.primary}
                      className="header-app-list-name"
                      mt={"0.25rem"}
                    >
                      {t(app.name)}
                    </Typography>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
      </Box>
    </>
  );
};

export default AppsMenu;
