import axios from 'axios';
// Helper
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { UserTrii } from '@trii/types/dist/Users';
import { UpdateUserStatus } from './types/UpdateUserStatus';
import { UpdateLanguageData } from './types/UpdateLanguageData';

const fetchUserInfo = async (jwtToken: string, URL: string): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserTrii>(URL, config);

  return response.data;
};

const updateUserStatus = async (
  jwtToken: string,
  URL: string,
  data: UpdateUserStatus
): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.put<UserTrii>(`${URL}/setStatus`, data, config);

  return response.data;
};

const updateLanguage = async (
  jwtToken: string,
  URL: string,
  data: UpdateLanguageData
): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.put(`${URL}/setLanguage`, data, config);

  return response.data;
};

const userSliceService = {
  fetchUserInfo,
  updateUserStatus,
  updateLanguage,
};

export default userSliceService;
