import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
// Types
import { RootState } from 'redux/rootReducer';
import { IContact } from '@trii/types/dist/Contacts';
import ContactSliceState from './types/ContactsSliceState';
import ContactsFetchData from 'types/ContactsFetchData';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Services
import contactService from './contactsService';
// Functions
import initRequestData from 'redux/functions/initRequestData';

const initialState: ContactSliceState = {
  contacts: [],
  status: {
    fetch: 'idle',
  },
};

export const fetchContacts = createAsyncThunk<
  IContact[],
  ContactsFetchData,
  { state: RootState }
>('contacts/fetchContacts', async (fetchInfo, { dispatch }) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  return await contactService.fetch(jwtToken, URL_CONTACTS, fetchInfo);
});

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetContacts: (state) => {
      state.contacts = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.contacts = action.payload;
      })
      .addCase(fetchContacts.pending, (state, action) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status.fetch = 'rejected';
      });
  },
});

const contactsState = (state: RootState) => state.Contacts;
export const selectContacts = createSelector(
  contactsState,
  (state) => state.contacts
);
export const selectContactsStatus = createSelector(
  contactsState,
  (state) => state.status.fetch
);

// Actions
export const { resetContacts } = contactsSlice.actions;

export default contactsSlice.reducer;
