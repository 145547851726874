import { Suspense } from 'react';
// Types
import RootProps from './types/RootProps';
// Translation
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// Context provider
import DataProvider from './context/contextApi';
import StyleProvider from './style/styleProvider';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
// Components/ui
import { Header } from './components';

interface Props {
  rootProps: RootProps;
}

import './App.css';
import { HeaderProvider } from 'components/Header/components/context/HeaderProvider';

const App = ({ rootProps }: Props) => {
  return (
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18next}>
        <DataProvider>
          <StyleProvider>
            <Provider store={store}>
              <HeaderProvider>
                <Header authenticated={true} rootProps={rootProps} />
              </HeaderProvider>
            </Provider>
          </StyleProvider>
        </DataProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
