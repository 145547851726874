import AppList from "./types/AppList";
import TriiSignatureIcon from './AppsIcon/utils/images/TriiSignature.svg';
import TriiDataSyncIcon from './AppsIcon/utils/images/TriiDataSync.svg';

const appDataList: AppList[] = [
    {
        id: 11,
        name: 'dropdownAppList.TriiDataSync',
        icon: TriiDataSyncIcon,
        url: '/a/dataSync',
        disabled: "disabled",
    },
    {
        id: 12,
        name: 'dropdownAppList.TriiSignature',
        icon: TriiSignatureIcon,
        url: '/a/signature',
        disabled: "disabled",

    },
];

export default appDataList;
