const getBasicRequestConfig = (jwt: string) => {
    return {
      headers: { Authorization: `Bearer ${jwt}` },
    };
  };
  const getContentTypeJsonRequestConfig = (jwt: string) => {
    return {
      headers: { Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' },
    };
  };
  
  const getRequestConfig = {
    basic: getBasicRequestConfig,
    contentTypeJson: getContentTypeJsonRequestConfig,
  };
  
  export default getRequestConfig;
  