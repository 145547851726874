import { Link as RouterLink } from "react-router-dom";
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Redux
import { useSelector } from "react-redux";
import { logout } from "redux/features/sessionSlice/sessionSlice";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
// Types
import { UserTrii } from "@trii/types/dist/Users";
// Components/ui
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/system";
// Icons
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// Components
import { ThemeSelector } from "./components";
import { selectedTheme } from "redux/features/themeSlice/themeSlice";
// Utils
import { getBadgeStatus } from "../../utils/getBadgeStatus";
import { updateLanguage } from "redux/features/userSlice/userSlice";

interface Props {
  user: UserTrii;
}

const MenuProfile = ({ user }: Props) => {
  const onSelectedTheme: Theme = useSelector(selectedTheme);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  // Detect system language
  const systemLanguage = navigator.language.startsWith("es")
    ? "espanol"
    : "ingles";

  // Initialize state
  const [languageSelected, setLanguageSelected] = useState(() => {
    const savedLanguage = localStorage.getItem("select");
    return savedLanguage ? JSON.parse(savedLanguage) : systemLanguage;
  });

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const openLinkNewTab = (event, url) => {
    event.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChangeLenguaje = useCallback(
    async (e) => {
      e.stopPropagation();
      const newLanguage = e.target.value;
      if (newLanguage === "espanol") {
        changeLanguage("ES");
        setLanguageSelected("espanol");
      } else {
        setLanguageSelected("ingles");
        changeLanguage("En");
      }

      localStorage.setItem("select", JSON.stringify(newLanguage));

      await dispatch(
        updateLanguage({ language: newLanguage === "espanol" ? "es" : "en" })
      );
      location.reload();
    },
    [dispatch, changeLanguage]
  );

  return (
    <Box
      sx={{
        backgroundColor: onSelectedTheme.palette.dropdownBox,
      }}
      className="header-menuProfileBox"
    >
      <Box
        className="header-ProfilePopover"
        sx={{
          backgroundImage: onSelectedTheme.palette.background.mainGradient,
        }}
      >
        <span className="header-mr-2">
          <Badge
            color="primary"
            overlap="circular"
            badgeContent={""}
            // variant="dot"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              "& .MuiBadge-badge": {
                height: 13,
                minWidth: 13,
                maxWidth: 13,
                borderRadius: "50%",
                backgroundColor: getBadgeStatus(user?.status),

                right: "0.5rem",
                // color: "#000000",
              },
              "& .MuiBadge-badge .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
                width: "0.8em",
                height: "0.8em",
                fill: "#000000",
              },
            }}
          >
            <Avatar src={user?.imageUrl} alt={user?.display_name} />
          </Badge>
        </span>
        <Box className="header-info-card-text">
          <Typography className="header-text-truncate header-text-truncate-md header-opacity-80 color-white">
            {user?.display_name}
          </Typography>
          <Typography
            className="header-text-truncate header-text-truncate-md color-white"
            sx={{
              opacity: "0.5",
              fontSize: "10px",
            }}
          >
            {user?.email}
          </Typography>
        </Box>
      </Box>
      <Box>
        <RouterLink to={"/a/settings/profile"}>
          <Link
            className="header-dropdown-item fw-500 header-py-3 header-position-relative"
            sx={{
              "&:hover": {
                backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
                color: onSelectedTheme.palette.primary.main,
              },
            }}
          >
            <OpenInNewIcon
              className="header-iconCornerRight"
              sx={{ display: "none", top: "10px", right: "15px" }}
              onClick={(e) => openLinkNewTab(e, "/a/settings/profile")}
            ></OpenInNewIcon>
            <Typography
              variant="body2"
              sx={{ color: onSelectedTheme.palette.text.primary }}
            >
              {t("global.profile")}
            </Typography>
          </Link>
        </RouterLink>
        <RouterLink to={"https://account.trii.app"}>
          <Link
            className="header-dropdown-item fw-500 header-py-3 header-position-relative"
            sx={{
              "&:hover": {
                backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
                color: onSelectedTheme.palette.primary.main,
              },
            }}
          >
            <OpenInNewIcon
              className="header-iconCornerRight"
              sx={{ display: "none", top: "10px", right: "15px" }}
              onClick={(e) => openLinkNewTab(e, "https://account.trii.app")}
            ></OpenInNewIcon>
            <Typography
              variant="body2"
              sx={{ color: onSelectedTheme.palette.text.primary }}
            >
              {t("dropdownMenuProfile.myAccount")}
            </Typography>
          </Link>
        </RouterLink>
        <Box
          className="header-dropdown-divider"
          sx={{ borderTop: `1px solid ${onSelectedTheme.palette.divider}` }}
        ></Box>
        {/*<SelectorIdiomas />*/}
        <Box id="idiomaEstilo" className="header-py-3 header-dropdown-divider">
          <Typography
            variant="body2"
            color={onSelectedTheme.palette.text.primary}
          >
            {t("global.languages")}
          </Typography>
          <FormControl fullWidth>
            <Select
              value={languageSelected}
              onChange={handleChangeLenguaje}
              className="header-adjust-select header-mt-2 selectDropProfile"
              size="small"
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                width: "100%",
                color: "text.primary",
                "& .MuiSelect-icon": {
                  color: "text.primary",
                },
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: "auto",
                  },
                },
                disablePortal: true,
                anchorEl: this,
                sx: { top: "215px", left: "5px" },
              }}
            >
              <MenuItem value={"espanol"}>{t("global.spanish")}</MenuItem>
              <MenuItem value={"ingles"}>{t("global.english")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          className="header-dropdown-divider"
          sx={{ borderTop: `1px solid ${onSelectedTheme.palette.divider}` }}
        ></Box>

        <ThemeSelector />
        <Box>
          <Box
            className="header-dropdown-divider"
            sx={{ borderTop: `1px solid ${onSelectedTheme.palette.divider}` }}
          ></Box>
          {/* <Link
            className="header-dropdown-item fw-500 header-py-3"
            href="/"
            sx={{
              '&:hover': {
                backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
              },
            }}
          >
            <Typography variant="body2" color={onSelectedTheme.palette.text.primary}>
              {t('dropdownMenuProfile.customerPortal')}
            </Typography>
          </Link> */}
          {/* Para volver a habilitarlo descomentar el LINK de arriba */}
          <Box
            component="div"
            className="header-dropdown-item fw-500 header-py-3"
            sx={{
              "&:hover": {
                backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
              },
              cursor: "not-allowed",
              color: "grey",
            }}
          >
            <Typography variant="body2">
              {t("dropdownMenuProfile.customerPortal")}
            </Typography>
          </Box>
        </Box>
        <Box
          className="header-dropdown-divider"
          sx={{ borderTop: `1px solid ${onSelectedTheme.palette.divider}` }}
        ></Box>
        <Button
          className="header-dropdown-item fw-500 header-py-3 header-px-6"
          onClick={handleLogout}
          sx={{
            "&:hover": {
              backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
            },
            textTransform: "none",
            justifyContent: "normal",
            width: "100%",
            borderRadius: 0,
          }}
        >
          <Typography
            variant="body2"
            color={onSelectedTheme.palette.text.primary}
          >
            {t("global.logout")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default MenuProfile;
