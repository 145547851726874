// Theme
import { useTheme } from "@mui/material";
// Types
import { UserStatus } from "@trii/types/dist/Users";

export const getBadgeStatus = (status: UserStatus) => {
  const theme = useTheme();
  switch (status) {
    case UserStatus.ONLINE:
      return theme.palette.success.main;
    case UserStatus.AWAY:
      return theme.palette.warning.main;
    case UserStatus.BUSY:
      return theme.palette.error.main;
    default:
      return theme.palette.grey[500];
  }
}