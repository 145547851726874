import { useCallback } from 'react';
import {
  Badge,
  Box,
  IconButton,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateTheme, selectedTheme } from 'redux/features/themeSlice/themeSlice';
import CircleIcon from '@mui/icons-material/Circle';
import {
  darkBlue,
  darkBlack,
  lightBlueIndigo,
  lightBlue,
  lightRed,
  lightGreen,
  lightPink,
  lightPurple,
  lightTeal,
  lightLime,
  lightYellow,
  lightAmber,
  lightOrange,
  lightBrown,
  lightGray,
} from 'style/themeProvider';
import { useTranslation } from 'react-i18next';

const ThemeSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSelectedTheme: Theme = useSelector(selectedTheme)

  const onChangeTheme = useCallback(async (event, themeName) => {
    if (event != null) {
      event.stopPropagation();
    }
    await dispatch(updateTheme(themeName));
    window.location.reload();
  }, []); //eslint-disable-line


  return (
    <Box className="header-py-3 header-dropdown-divider  header-d-flex header-align-items-center justify-content-between">
      <Box display="flex" flexDirection="column">
        {/* Temas claros */}
        <Box display="flex" flexDirection="column">
          <Typography variant="body2" color={onSelectedTheme.palette.text.primary}>
            {t("dropdownMenuProfile.themes")}
          </Typography>
          <Stack direction="row" alignItems="center" sx={{ flexFlow: 'wrap' }}>
            {/* Tema Azul oscuro (por Defecto) */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightBlueIndigo')}
            >
              {onSelectedTheme.name === 'lightBlueIndigo' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      // background: 'linear-gradient(270deg, #215c97, #1976d2)',
                      background: lightBlueIndigo.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    // background: 'linear-gradient(270deg, #215c97, #1976d2)',
                    background: lightBlueIndigo.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Azul claro*/}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightBlue')}
            >
              {onSelectedTheme.name === 'lightBlue' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      // background: 'linear-gradient(270deg, #215c97, #1976d2)',
                      background: lightBlue.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    // background: 'linear-gradient(270deg, #215c97, #1976d2)',
                    background: lightBlue.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Gris */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightGray')}
            >
              {onSelectedTheme.name === 'lightGray' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightGray.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightGray.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Rojo */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightRed')}
            >
              {onSelectedTheme.name === 'lightRed' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      // background: 'linear-gradient(270deg, #ff8585, #f44336)',
                      background: lightRed.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    // background: 'linear-gradient(270deg, #ff8585, #f44336)'
                    background: lightRed.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Rosa */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightPink')}
            >
              {onSelectedTheme.name === 'lightPink' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightPink.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightPink.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>

            {/* Tema Púrpura */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightPurple')}
            >
              {onSelectedTheme.name === 'lightPurple' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightPurple.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightPurple.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Lima */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightLime')}
            >
              {onSelectedTheme.name === 'lightLime' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightLime.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightLime.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Verde */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightGreen')}
            >
              {onSelectedTheme.name === 'lightGreen' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightGreen.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightGreen.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Verde azulado */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightTeal')}
            >
              {onSelectedTheme.name === 'lightTeal' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightTeal.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightTeal.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Marron */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightBrown')}
            >
              {onSelectedTheme.name === 'lightBrown' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightBrown.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightBrown.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Naranja */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightOrange')}
            >
              {onSelectedTheme.name === 'lightOrange' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightOrange.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightOrange.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            {/* Tema Ámbar */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightAmber')}
            >
              {onSelectedTheme.name === 'lightAmber' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightAmber.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightAmber.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>

            {/* Tema Amarillo */}
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'lightYellow')}
            >
              {onSelectedTheme.name === 'lightYellow' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: lightYellow.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: lightYellow.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
          </Stack>
        </Box>

        {/* Temas oscuros */}
        <Box display="flex" flexDirection="column">
          <Typography variant="body2" color={onSelectedTheme.palette.text.primary}>
            {t("dropdownMenuProfile.darkThemes")}
          </Typography>
          <Stack direction="row" alignItems="center">
            <IconButton
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'darkBlack')}
            >
              {onSelectedTheme.name === 'darkBlack' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: darkBlack.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: darkBlack.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
            <IconButton
              id="darkBlue"
              aria-label="delete"
              onClick={(event) => onChangeTheme(event, 'darkBlue')}
            >
              {onSelectedTheme.name === 'darkBlue' ? (
                <Badge
                  color="success"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CircleIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      color: 'transparent',
                      width: '20px',
                      height: '20px',
                      background: darkBlue.palette.background.mainGradient,
                    }}
                  />
                </Badge>
              ) : (
                <CircleIcon
                  fontSize="inherit"
                  sx={{
                    borderRadius: '50%',
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    background: darkBlue.palette.background.mainGradient,
                  }}
                />
              )}
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ThemeSelector;
