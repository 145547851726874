import { Fragment, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectedTheme } from 'redux/features/themeSlice/themeSlice';
// Components/ui
import { Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { NotificationDrawer } from './components';
// Types
import { Theme } from '@mui/system';
import type AnchorState from './types/AnchorState';

export default function Notifications() {
  const onSelectedTheme: Theme = useSelector(selectedTheme);
  const [state, setState] = useState<AnchorState>({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <Box>
      {['right'].map((anchor) => (
        <Fragment key={anchor}>
          <IconButton
            onClick={toggleDrawer(anchor, true)}
          >
            <NotificationsIcon
              sx={{
                color: onSelectedTheme.palette.color.icons,
                '&:hover': { color: onSelectedTheme.palette.color.icons },
                '&:.header-icon:not(.btn)[data-toggle="dropdown"][aria-expanded="true"]':
                  { color: onSelectedTheme.palette.color.icons },
                cursor: 'pointer',
                display: 'flex',
              }}
            />
          </IconButton>
          <NotificationDrawer
            anchor={anchor}
            state={state}
            toggleDrawer={toggleDrawer}
          />
        </Fragment>
      ))}
    </Box>
  );
}
