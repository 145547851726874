import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import initRequestData from '../../functions/initRequestData';
import { UserSliceState } from './types/UserSliceState';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RootState } from '../../store';
import { StorageAzureSAS, UserStatus, UserTrii } from '@trii/types/dist/Users';
import { UpdateUserStatus } from './types/UpdateUserStatus';
// Service
import userSliceService from './userSliceService';
import { UpdateLanguageData } from './types/UpdateLanguageData';

const initialState: UserSliceState = {
  user: null,
  status: {
    update: 'idle',
  },
};

export const getUser = createAsyncThunk<UserTrii, undefined, { state: RootState }>(
  'User/getUser',
  async (_, { dispatch }) => {
    const { jwtToken, URL_USER } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const triiUser = await userSliceService.fetchUserInfo(jwtToken, URL_USER);

    return triiUser;
  }
);

export const updateSas = createAsyncThunk(
  'contacts/updateSas',
  async (_, { dispatch }) => {
    const response = await dispatch(getUser());
    const userTrii = response.payload as UserTrii;
    const { storageAzureSAS } = userTrii;

    return storageAzureSAS;
  }
);

export const fetchUpdateUserStatus = createAsyncThunk(
  'User/fetchUpdateUserStatus',
  async (data: UpdateUserStatus, { dispatch }) => {
    const { jwtToken, URL_USER } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const triiUser = await userSliceService.updateUserStatus(
      jwtToken,
      URL_USER,
      data
    );

    return triiUser;
  }
);

export const updateLanguage = createAsyncThunk(
  'User/updateLanguage',
  async (data: UpdateLanguageData, { dispatch }) => {
    const { jwtToken, URL_USER } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await userSliceService.updateLanguage(jwtToken, URL_USER, data);
    return response;
  }
);

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserTrii>) => {
      state.user = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<UserStatus>) => {
      state.user.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.fulfilled, (state, action: PayloadAction<UserTrii>) => {
        console.log('success fetching user: ', action.payload);
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        console.log('error fetching user: ', action.error);
      })
      .addCase(fetchUpdateUserStatus.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(
        fetchUpdateUserStatus.fulfilled,
        (state, action: PayloadAction<UserTrii>) => {
          console.log('success fetching user: ', action.payload);
          state.status.update = 'succeeded';
          state.user = action.payload;
        }
      )
      .addCase(fetchUpdateUserStatus.rejected, (state, action) => {
        state.status.update = 'rejected';
        console.log('error fetching user: ', action.error);
      })
      .addCase(
        updateSas.fulfilled,
        (state, action: PayloadAction<StorageAzureSAS>) => {
          state.user.storageAzureSAS = action.payload;
        }
      );
  },
});
export const { setUser, setUserStatus } = userSlice.actions;
const selectUserState = (state: RootState) => state.User;
// Selectors
export const selectUser = createSelector(selectUserState, (user) => user.user);
export const selectUserUpdateStatus = createSelector(
  selectUserState,
  (user) => user.status.update
);

export default userSlice.reducer;
