import { useEffect } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectedTheme } from 'redux/features/themeSlice/themeSlice';
// Types
import { Theme } from '@mui/system';
// Components
import { Box, Typography, IconButton } from '@mui/material';
import { ClearButton } from './components';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
// Types
import { UseDrawerContentReturn } from '../../hooks/useDrawerContent';

interface Props {
  onClose: (event: any) => void;
  drawerContent: UseDrawerContentReturn;
}

const Header = ({ onClose, drawerContent }: Props) => {
  const onSelectedTheme: Theme = useSelector(selectedTheme);
  const { t } = useTranslation();

  const { content, showSettings, showNotifications } = drawerContent;

  useEffect(() => {
    return showNotifications();
  }, []);

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        background: onSelectedTheme.palette.dropdownBox,
        border: `1px solid ${onSelectedTheme.palette.divider}`,
        zIndex: 1,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        color={onSelectedTheme.palette.text.primary}
      >
        <Typography variant="h6">{t('notification.title')}</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          {content === 'notifications' && (
            <IconButton onClick={showSettings} size="small">
              <SettingsIcon sx={{ color: onSelectedTheme.palette.text.primary }} />
            </IconButton>
          )}
          {content === 'settings' && (
            <IconButton onClick={showNotifications} size="small">
              <NotificationsIcon
                sx={{ color: onSelectedTheme.palette.text.primary }}
              />
            </IconButton>
          )}
          <IconButton size="small" onClick={onClose}>
            <CloseIcon sx={{ color: onSelectedTheme.palette.text.primary }} />
          </IconButton>
        </Box>
      </Box>
      {content === 'notifications' && <ClearButton />}
    </Box>
  );
};

export default Header;
