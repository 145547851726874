import { useState, useEffect } from 'react';
// Redux
import { useAppDispatch } from './useAppDispatch';
import { useSelector } from 'react-redux';

import { selectUser, updateSas } from 'redux/features/userSlice/userSlice';
import { StorageAzureSAS } from '@trii/types/dist/Users';
// Utils
import { v4 as uuidv4 } from 'uuid';

export type UseImageType = {
  imageUrl: string;
  removeParams: (url: string) => string;
};

const useImage = (initialState: string): UseImageType => {
  const [imageUrl, setImageUrl] = useState(initialState);

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  async function getSas() {
    const response = await dispatch(updateSas());
    const storageAzureSAS = response.payload as StorageAzureSAS;

    return storageAzureSAS.sas;
  }

  const getImageURLWithAccessToken = async (imageUrl: string) => {
    const expiredAtDate = new Date(user.storageAzureSAS.expireAt);
    let sas = '';

    if (expiredAtDate < new Date()) {
      sas = await getSas();
    } else {
      sas = user.storageAzureSAS.sas;
    }

    const randomParam = uuidv4();

    return `${imageUrl}${sas}&cacheId=${randomParam}`;
  };

  const setAccessImageUrl = async (url: string) => {
    if (user.storageAzureSAS && url) {
      const imageUrl = await getImageURLWithAccessToken(url);
      setImageUrl(imageUrl);
    }
  };

  function removeSasToken(url: string) {
    return url.split('?sv=')[0];
  }

  function removeCacheId(url: string) {
    return url.split('&cacheId=')[0];
  }

  function removeParams(url: string) {
    let result = removeSasToken(url);
    result = removeCacheId(result);

    return result;
  }

  useEffect(() => {
    if (initialState) {
      const imageUrl = removeSasToken(initialState);
      setAccessImageUrl(imageUrl);
    }
  }, [initialState]);

  return {
    imageUrl,
    removeParams,
  };
};

export default useImage;
