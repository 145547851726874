import { useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Space
import { getSpaces, selectSpaces } from 'redux/features/spacesSlice/spacesSlice';
// Components/ui
import {
  SwipeableDrawer,
  Typography,
  Divider,
  IconButton,
  styled,
  useTheme,
  List,
  ListItemText,
  Link,
  ListItemButton,
  Box,
} from '@mui/material';
// Icons
import {
  ChevronLeft,
  ChevronRight,
  OpenInNew,
} from "@mui/icons-material";
import { Theme } from "@mui/system";
import {selectedTheme} from "../../../../../../redux/features/themeSlice/themeSlice";
import { Header, Space } from './components';


interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SpaceSelector = ({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const spaces = useSelector(selectSpaces);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'click') {
      setOpen(open);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getSpaces());
  }, [dispatch]);
  const onSelectedTheme: Theme = useSelector(selectedTheme)

  return (
    <SwipeableDrawer
      anchor={"left"}
      swipeAreaWidth={0.1}
      open={open}
      onOpen={() => toggleDrawer(true)}
      onClose={toggleDrawer(false)}
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
          backgroundColor: onSelectedTheme.palette.background.default,
          backgroundImage: 'unset',

        },

      }}
    >
      <Header handleDrawerClose={handleDrawerClose} />
      <Divider />
      {spaces?.map((space) => (
        <Space key={space.id} space={space} />
      ))}
    </SwipeableDrawer>
  );
};

export default SpaceSelector;
