import { useState } from "react"
// Components/ui
import { Box, IconButton, Modal } from "@mui/material"
// Icons
import { Help } from "@mui/icons-material"
// Components
import {
  KeyboardShortcutsModal
} from "./components"

const KeyboardShortcutsHelp = () => {
  const [open, setOpen] = useState(false)

  const toggleModal = () => {
    setOpen(!open)
  }

  return (
    <>
      <IconButton
        onClick={toggleModal}
      >
        <Help
          sx={{
            // @ts-ignore
            color: (theme) => theme.palette.color.icons,
          }}
        />
      </IconButton>
      <Modal
        open={open}
        onClose={toggleModal}
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          sx={{
            width: "60vw",
            height: "80vh",
            boxShadow: theme => theme.shadows[5],
          }}
        >
          <KeyboardShortcutsModal
            toggleModal={toggleModal}
          />
        </Box>
      </Modal>
    </>
  )
}

export default KeyboardShortcutsHelp