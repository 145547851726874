// Types
import ContactsFetchData, { Format } from 'types/ContactsFetchData';


export default function getNewContactFetchData(
  filterValue: string,
  format: Format,
): ContactsFetchData {
  return {
    currentPage: 1,
    perPage: 50,
    nombre: '',
    operator: 'OR',
    order: 'ASC',
    orderColumn: '',
    filter: [
      {
        column: 'name',
        condition: '',
        value: filterValue,
      },
      {
        column: 'email',
        condition: '',
        value: filterValue,
      },
      {
        column: 'phone',
        condition: '',
        value: filterValue,
      },
    ],
    email: '',
    format,
  };
}
