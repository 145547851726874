import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
// Types
import type NotificationSlice from './types/NotificationSlice';
import type { RootState } from 'redux/rootReducer';
import { INotification } from '@trii/types/dist/Common/Notifications';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { UpdateNotification } from './types/UpdateNotification';
// Utils
import initRequestData from 'redux/functions/initRequestData';
// Service
import notificationService from './notificationService';
import { ChangeNotificationsConfigData } from './types/ChangeNotificationsConfigData';

const initialState: NotificationSlice = {
  notifications: [],
  devices: [],
  originalNotificationsConfig: null, // Used to store the original settings
  notificationsConfig: null, // Used to store the current settings
  status: {
    fetch: 'idle',
    update: 'idle',
    notificationsConfigUpdate: 'idle',
    devices: 'idle',
  },
};

export const fetchNotifications = createAsyncThunk<
  INotification[],
  void,
  { state: RootState }
>('notification/fetchNotifications', async (_, { dispatch }) => {
  const { jwtToken, URL_NOTIFICATION } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  return await notificationService.fetch(jwtToken, URL_NOTIFICATION);
});

export const fetchUpdateNotification = createAsyncThunk(
  'notification/fetchUpdateNotification',
  async (data: UpdateNotification, { dispatch }) => {
    const { jwtToken, URL_NOTIFICATION } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    return await notificationService.fetchUpdateNotification(
      jwtToken,
      URL_NOTIFICATION,
      data
    );
  }
);

export const fetchNotificationsSettings = createAsyncThunk(
  'notification/fetchSettings',
  async (_, { dispatch }) => {
    const { jwtToken, URL_NOTIFICATION } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    return await notificationService.fetchSettings(jwtToken, URL_NOTIFICATION);
  }
);

export const updateNotificationsSettings = createAsyncThunk(
  'notification/updateSettings',
  async (_, { dispatch, getState }) => {
    const { jwtToken, URL_NOTIFICATION } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const state = getState() as RootState;
    const data = state.Notification.notificationsConfig;

    return await notificationService.saveSettings(jwtToken, URL_NOTIFICATION, data);
  }
);

export const fetchDevices = createAsyncThunk(
  'notification/fetchDevices',
  async (_, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    return await notificationService.fetchDevices(jwtToken);
  }
);

const nofiticationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getNewNotifications: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    changeNotificationsConfig: (
      state,
      action: PayloadAction<ChangeNotificationsConfigData>
    ) => {
      const { section, itemKey, valueName, value } = action.payload;

      state.notificationsConfig[section][itemKey][valueName] = value;
    },
    resetNotificationsConfig: (state) => {
      state.notificationsConfig = state.originalNotificationsConfig;
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.notifications = action.payload;
      })
      .addCase(fetchNotifications.pending, (state, action) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchUpdateNotification.fulfilled, (state) => {
        state.status.update = 'succeeded';
      })
      .addCase(fetchUpdateNotification.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(fetchNotificationsSettings.fulfilled, (state, action) => {
        state.notificationsConfig = action.payload;
      })
      .addCase(updateNotificationsSettings.fulfilled, (state, action) => {
        state.status.notificationsConfigUpdate = 'idle';
        state.originalNotificationsConfig = action.payload;
      })
      .addCase(updateNotificationsSettings.pending, (state) => {
        state.status.notificationsConfigUpdate = 'loading';
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.devices = action.payload;
      })
      .addCase(fetchDevices.pending, (state) => {
        state.status.devices = 'loading';
      });
  },
});

const notificationState = (state: RootState) => state.Notification;
export const selectNotifications = createSelector(
  notificationState,
  (state) => state.notifications
);
export const selectNotificationsFetchStatus = createSelector(
  notificationState,
  (state) => state.status.fetch
);
export const selectNotificationsUpdateStatus = createSelector(
  notificationState,
  (state) => state.status.update
);
export const selectNotificationsConfig = createSelector(
  notificationState,
  (state) => state.notificationsConfig
);
export const selectNotificationsConfigUpdateStatus = createSelector(
  notificationState,
  (state) => state.status.notificationsConfigUpdate
);
export const selectDevices = createSelector(
  notificationState,
  (state) => state.devices
);
export const selectDevicesFetchStatus = createSelector(
  notificationState,
  (state) => state.status.devices
);

// Actions
export const {
  getNewNotifications,
  changeNotificationsConfig,
  resetNotificationsConfig,
  clearNotifications,
} = nofiticationSlice.actions;

export default nofiticationSlice.reducer;
