import moment from 'moment';
// Components/iu
import { Box, Typography } from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ComputerIcon from '@mui/icons-material/Computer';
// Types
import { DeviceType, IDevice } from '@trii/types/dist/Accounts';

type DeviceItemProps = {
  data: IDevice;
};

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

const DeviceItem = ({ data }: DeviceItemProps) => {
  function getIcon() {
    switch (data.type) {
      case DeviceType.MOBILE_ANDROID:
        return <PhoneAndroidIcon fontSize="large" />;
      case DeviceType.MOBILE_OTHER:
        return <PhoneAndroidIcon fontSize="large" />;
      case DeviceType.MOBILE_WINDOWS:
        return <PhoneAndroidIcon fontSize="large" />;
      case DeviceType.MOBILE_IOS:
        return <PhoneAndroidIcon fontSize="large" />;
      case DeviceType.PC_LINUX:
        return <ComputerIcon fontSize="large" />;
      case DeviceType.PC_WINDOWS:
        return <ComputerIcon fontSize="large" />;
      case DeviceType.PC_MAC:
        return <ComputerIcon fontSize="large" />;
      case DeviceType.PC_OTHER:
        return <ComputerIcon fontSize="large" />;
      default:
        return <ComputerIcon fontSize="large" />;
    }
  }

  return (
    <Box sx={{ display: 'flex', gap: '15px', padding: '5px' }}>
      <Box
        sx={{
          marginY: 'auto',
          padding: '5px',
        }}
      >
        {getIcon()}
      </Box>
      <Box>
        <Typography>{data.lastAccess?.location}</Typography>
        <Typography>
          {moment(data.lastAccess?.datetime).format(DATE_FORMAT)}
        </Typography>
        <Typography>{data.info}</Typography>
      </Box>
    </Box>
  );
};

export default DeviceItem;
