// Components/ui
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { changeNotificationsConfig } from 'redux/features/notificationSlice/notificationSlice';
// Types
import { UserConfig_SendTo } from '@trii/types/dist/Common/Notifications';
import { SettingsSection } from 'redux/features/notificationSlice/types/SettingsSection';
import { NotificationsConfigItemKey } from 'redux/features/notificationSlice/types/NotificationsConfigItemKey';
import { useTranslation } from 'react-i18next';

type SettingOptionItemProps = {
  itemKey: NotificationsConfigItemKey;
  item: UserConfig_SendTo;
  section: SettingsSection;
};
const SettingOptionItem = ({ itemKey, item, section }: SettingOptionItemProps) => {
  const dispatch = useAppDispatch();

  const handleChange = (valueName: 'email' | 'push', value: boolean) => {
    dispatch(changeNotificationsConfig({ section, itemKey, valueName, value }));
  };
  const { t } = useTranslation();

  let title = '';
  let description = '';
  if (section === 'conversations') {
    title = t(`notification.conversations.${itemKey}_title`);
    description = t(`notification.conversations.${itemKey}_description`);
  } else if (section === 'chat') {
    title = t(`notification.chat.${itemKey}_title`);
    description = t(`notification.chat.${itemKey}_description`);
  } else if (section === 'tickets') {
    title = t(`notification.tickets.${itemKey}_title`);
    description = t(`notification.tickets.${itemKey}_description`);
  }
  return (
    <Box display="flex" alignItems="flex-start" justifyContent={'space-between'}>
      <Box>
        <Typography variant="h6" sx={{ fontSize: '1.125rem' }}>
          {title}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: '0.875rem', opacity: 0.7 }}>
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => handleChange('email', e.target.checked)}
              name="email"
              checked={item.email}
            />
          }
          label="Email"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => handleChange('push', e.target.checked)}
              name="push"
              checked={item.push}
            />
          }
          label="Push"
        />
      </Box>
    </Box>
  );
};

export default SettingOptionItem;
